import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout_contact'
import SEO from '../components/seo'

const ContactPage = () => (
    <Layout pageInfo={{ pageName: "contact", pageTitle: "CONTACT" }}>
        <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container className="mt-4">
            <h4>Contact David L. Waldron</h4>
            <p>Phone: 360 378 3070<br/>
            Email: <a href="mailto:davidwaldron@interisland.net">davidwaldron@interisland.net</a>
            </p>
            <p>
            Mailing address:P. O. Box 2505<br/>
            Friday Harbor WA 98250<br/>
            Street address:<br/>
            310 Spring Street<br/>
            Friday Harbor WA 98250
            </p>
        </Container>
    </Layout>
    
)

export default ContactPage